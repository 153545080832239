// All your reusable functions goes here

export const formatNumberInThousand = (num) => {
  if (num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  } else {
    return;
  }
};

export const formatToTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getInitials = (fullName) => {
  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
};

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  var successful;
  try {
    successful = document.execCommand("copy");
    //  successful ? "successful" : "unsuccessful";
    // console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    // console.error("Fallback: Oops, unable to copy", err);
    throw err;
  }

  document.body.removeChild(textArea);
  return successful;
}

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(
    function () {
      return true;
      // console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      return false;
      // console.error("Async: Could not copy text: ", err);
    }
  );
};
