import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import MerchantDetails from "../pages/merchants/MerchantDetails";
import Merchants from "../pages/merchants/Merchants";
import AddNewMember from "../pages/teams/AddNewMember";
import Transactions from "../pages/transactions/Transactions";
import Teams from "../pages/teams/Teams";
import Login from "../pages/auth/login/Login";
import ProtectedRoute from "./ProtectedRoute";
import {useSelector} from "react-redux";
import {loginSelector} from "../store/slices/auth/loginSlice";
import FundRequests from "../pages/fund-requests/FundRequests";
import WithdrawalRequests from "../pages/withdrawal-requests/WithdrawRequests";

function RoutesComponent() {
  const {userData} = useSelector(loginSelector).login;
  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<Login />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute component={Dashboard} />} />
        <Route
          path="/merchants"
          element={<ProtectedRoute component={Merchants} />}
        />
        <Route
          path="/merchant/:phone"
          element={<ProtectedRoute component={MerchantDetails} />}
        />
        {/* <Route path="/loans" element={<ProtectedRoute component={Loans} />} />
        <Route
          path="/loan/details"
          element={<ProtectedRoute component={LoanDetails} />}
        />
        <Route
          path="/loan-history"
          element={<ProtectedRoute component={UserLoanHistory} />}
        /> */}

        {userData?.role === "super-admin" &&
          <>
            <Route path="/teams" element={<ProtectedRoute component={Teams} />} />
            <Route
              path="/teams/add"
              element={<ProtectedRoute component={AddNewMember} />}
            />
          </>
        }

        <Route
          path="/transactions"
          element={<ProtectedRoute component={Transactions} />}
        />
        <Route
          path="/funding-requests"
          element={<ProtectedRoute component={FundRequests} />}
        />
        <Route
          path="/withdrawal-requests"
          element={<ProtectedRoute component={WithdrawalRequests} />}
        />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </Router>
  );
}

export default RoutesComponent;
