import React, { useEffect, useState } from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardSelector,
  getwithdrawalRequest,
} from "../../store/slices/dashboard/dashboardSlice";
import Loader from "../../widgets/others/Loader";
import FetchError from "../../widgets/others/FetchError";
import RequestTable from "../../components/withdraw_requests/RequestTable";

export default function WithdrawalRequests() {
  const {
    withdrawalRequests,
    transactionsIsLoading,
    transactionsError,
  } = useSelector(dashboardSelector).dashboard;
  const dispatch = useDispatch();
  const [filteredTransactions, setFilteredTransactions] = useState(
    withdrawalRequests
  );
  const [searchedTransactions, setSearchedTransactions] = useState(
    filteredTransactions
  );
  const [searchValue, setsearchValue] = useState("");
  const [filterValues, setFilterValues] = useState({ entries: 0 });

  useEffect(() => {
    // set argument true for background fetch not affecting the ui
    dispatch(getwithdrawalRequest(true));
  }, [dispatch]);

  useEffect(() => {
    setFilteredTransactions(withdrawalRequests);
    setFilter(filterValues);
    //eslint-disable-next-line
  }, [withdrawalRequests]);

  const setFilter = (values) => {
    setFilterValues(values);
    setFilteredTransactions(
        withdrawalRequests?.filter(
        (each, i) =>
          Number(values.entries) === 0 || i + 1 <= Number(values.entries)
        // (!values.date || Date(each.createdAt).getMilliseconds() === Date(values.date).getMilliseconds())
      )
    );
  };

  useEffect(() => {
    setSearchedTransactions(filteredTransactions);
    searchTransactions(searchValue);
    //eslint-disable-next-line
  }, [filteredTransactions]);

  const searchTransactions = (searchText) => {
    setsearchValue(searchText);
    setSearchedTransactions(
        withdrawalRequests?.filter(
        (each, i) =>
          each?.account_name?.toLowerCase().includes(searchText.toLowerCase()) ||
          each?.bank_name?.toLowerCase().includes(searchText.toLowerCase()) ||
          each?.request_type?.toLowerCase().includes(searchText.toLowerCase()) ||
          each?.is_status?.toLowerCase().includes(searchText.toLowerCase()) ||
          each?.reason_for_declined
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          each?.createdAt.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };
  return (
    <>
      <PageHeader
        title="Withdrawal Requests"
        withSearch={true}
        withFilter={true}
        setFilter={setFilter}
        search={searchTransactions}
      />

      {transactionsIsLoading && <Loader height={"40vh"} />}

      {transactionsError && !transactionsIsLoading && (
        <FetchError
          message={"An error occured, please check your connection"}
          minHeight={"40vh"}
          btnText={"Try Again"}
          onClick={() => dispatch(getwithdrawalRequest())}
        />
      )}

      {withdrawalRequests?.length === 0 && !transactionsIsLoading && !transactionsError && (
        <FetchError
          message={"No Requests found"}
          minHeight={"40vh"}
          btnText={"Refresh"}
          onClick={() => dispatch(getwithdrawalRequest())}
        />
      )}

      {withdrawalRequests?.length > 0 && !transactionsIsLoading && !transactionsError && (
        <div className="container-fluid pt-4">
          <p className="text-center text-danger">,<b>NOTE:</b> kindly approve before disbursing user's withdrawal request.</p>
          <section className="row">
            <div className="col-sm-12">
              <RequestTable transactions={[...searchedTransactions]} />
            </div>
          </section>
        </div>
      )}
    </>
  );
}
